<template>
  <el-container class="container">
    <el-header class="header">
      <header-top></header-top>
    </el-header>
    <el-main class="main">
      <el-row :gutter="30">
        <el-col :xs="15" :sm="15" :md="15" :lg="14" :xl="16" class="con-left">
          <el-tabs v-model="activeName">
            <el-tab-pane label="编译信息" name="first" v-if="getCompileState(this.result)">
              <div class="leftitem">
                <div class="contents">
                  <div class="contentshow" style="overflow: scroll">
                    <pre v-text="CompileInformation"></pre>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="源代码" name="second">
              <div class="leftitem">
                <div class="contents">
                  <el-row style="display: flex;align-items: center">
                    <div class="linevertical"></div>
                    <span>源代码</span>
                    <el-button
                      style="margin-left: 15px;"
                      type="warning"
                      size="mini"
                      plain
                      v-clipboard:copy="invitationCode"
                      v-clipboard:success="copy"
                      v-clipboard:error="onError"
                    >复制</el-button>
                  </el-row>
                  <div class="contentshow" style="overflow: scroll" v-highlight>
                    <pre>
                      <code v-text="showSourse"></code>
                    </pre>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="运行信息" name="third" v-if="getRunState(this.result)">
              <div class="leftitem">
                <div class="contents">
                  <div class="contentshow" style="overflow: scroll">
                    <pre v-text="runInformation"></pre>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="判题结果" name="fourthly" v-if="getTopicState(this.result)">
              <div class="leftitem">
                  <div class="contents">
                    <div class="contentshow" style="overflow: scroll">
                      <pre v-text="runInformation"></pre>
                    </div>
                  </div>
                </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :xs="9" :sm="9" :md="9" :lg="10" :xl="8" class="con-right">
          <el-row class="rightcontent">
            <el-col class="title">
              <span v-text="stuId" style="margin-right: 20px;"></span>
              <span v-text="stuName"></span>
            </el-col>
            <el-col class="secondtitle colcls">
              所属题目：
              <span style="margin-left: 15px;" v-text="problemName"></span>
            </el-col>
            <el-col class="secondtitle colcls">
              提交结果：
              <span
                style="margin-left: 15px;font-weight: bold"
                v-text="getSubType(this.result)"
                :style="'color'+':'+ getStateColor(this.result)"
              >正确</span>
            </el-col>
            <el-col class="secondtitle showmessage">
              提交详情：
              <div style="margin-left:15px;">
                <i class="el-icon-timer"></i>
                <span style="margin-left: 5px;" v-text="time"></span>ms
              </div>
              <div style="margin-left: 15px;">
                <i class="el-icon-coin"></i>
                <span style="margin-left: 5px;" v-text="memory"></span>KB
              </div>
              <div style="margin-left: 8px;">
                <i class="el-icon-document"></i>
                <span style="margin-left: 5px;" v-text="code_length"></span> B
                <span style="margin-left: 5px;" v-text="getLanText(this.language)"></span>
              </div>
            </el-col>
            <el-col class="secondtitle colcls">
              提交时间：
              <span v-text="subTime" style="margin-left:15px"></span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import HeaderTop from '@/components/HeaderTop'
import { getSubmitHistoryItem } from '@/apiteacher/teacher'

export default {
  components: { HeaderTop },
  data () {
    return {
      activeName: 'second',
      invitationCode: '',
      solution_id: '', // 提交记录id
      runInformation: '', // 编译信息
      CompileInformation: '', // 编译信息
      stuId: '', // 提交人id
      stuName: '', // 提交人姓名
      belongTopic: '', // 所属题目
      result: '', // 提交结果
      subTime: '', // 提交时间
      time: '', // 用时
      language: '', // 语言
      code_length: '',
      memory: '', // 内存大小
      problemName: '', // 问题名称
      showSourse: ''
    }
  },
  mounted () {
    let resultData = JSON.parse(this.$route.query.resultData)
    this.solution_id = resultData.solution_id
    this.stuId = resultData.loginName
    this.stuName = resultData.name
    this.belongTopic = resultData.contestName
    this.problemName = resultData.problemName
    this.result = resultData.result
    this.subTime = resultData.in_date
    this.time = resultData.time
    this.code_length = resultData.code_length
    this.language = resultData.language
    this.memory = resultData.memory
    this.getSubmitHistoryItem()
  },
  methods: {
    copy (e) {
      window.$msg('复制成功')
    },
    onError (e) {
      window.$msg('复制失败')
    },

    // 获取提交详情
    getSubmitHistoryItem () {
      getSubmitHistoryItem({
        solution_id: this.solution_id
      }).then(res => {
        if (res.state === 'success') {
          this.$nextTick(() => {
            this.invitationCode = res.body.source
            this.showSourse = res.body.source
            this.runInformation = res.body.runtimeInfo
            this.CompileInformation = res.body.compileInfo
          })
        }
      })
      // fGet(`/question/getSubmitHistoryItem?solution_id=${this.solution_id}`)
      //   .then(res => {
      //     if (res.state === "success") {
      //       this.$nextTick(() => {
      //         this.invitationCode = res.body.source;
      //         this.showSourse = res.body.source;
      //         this.runInformation = res.body.runtimeInfo;
      //         this.CompileInformation = res.body.compileInfo;
      //       });
      //     } else {
      //       $msg(res.errMsg, 2);
      //     }
      //   })
      //   .catch(err => {
      //     $msg("请求接口失败", 1);
      //   });
    },

    getSubType (state) {
      switch (state) {
        case 0:
          return '等待'
        case 1:
          return '等待重判'
        case 2:
          return '编译中'
        case 3:
          return '运行并评判'
        case 4:
          return '正确'
        case 5:
          return '格式错误'
        case 6:
          return '答案错误'
        case 7:
          return '时间超限'
        case 8:
          return '内存超限'
        case 9:
          return '输出超限'
        case 10:
          return '运行错误'
        case 11:
          return '编译错误'
        case 12:
          return '编译成功'
        case 13:
          return '运行完成'
        default:
          return '未知状态'
      }
    },
    getStateColor (state) {
      switch (state) {
        case 4:
          return '#4DA54D'
        case 12:
          return '#4DA54D'
        case 13:
          return '#4DA54D'
        case 5:
          return '#CE433F'
        case 6:
          return '#CE433F'
        case 10:
          return '#CE433F'
        case 11:
          return '#CE433F'
        default:
          return '#EB9316'
      }
    },

    // 获取语言
    getLanText (state) {
      switch (state) {
        case 0: {
          return 'C'
        }
        case 1: {
          return 'C++'
        }
        case 2: {
          return 'Pascal'
        }
        case 4: {
          return 'Ruby'
        }
        case 5: {
          return 'Bash'
        }
        case 6: {
          return 'Python'
        }
        case 7: {
          return 'php'
        }
        case 8: {
          return 'perl'
        }
        case 9: {
          return 'c#'
        }
        case 10: {
          return 'objectiveC'
        }
        case 11: {
          return 'freeBasic'
        }
        case 12: {
          return 'scheme'
        }
        case 13: {
          return 'Clang'
        }
        case 14: {
          return 'Clang++'
        }
        case 15: {
          return 'Lua'
        }
        case 16: {
          return 'JavaScript'
        }
        case 17: {
          return 'Go'
        }
      }
    },

    // 编译信息
    getCompileState (state) {
      switch (state) {
        case 11: {
          return true
        }
        default: {
          return false
        }
      }
    },

    // 运行信息
    getRunState (state) {
      switch (state) {
        case 10: {
          return true
        }
        default: {
          return false
        }
      }
    },

    // 判题结果
    getTopicState (state) {
      switch (state) {
        case 6: {
          return true
        }
        default: {
          return false
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.contentshow {
  border: 1px solid #a6a6a6;
  margin: 15px;
  padding: 15px;
  line-height: 30px;
}

.tipheader {
  margin-left: 30px;
  margin-top: 10px;
}

.linevertical {
  background-color: #f5c319;
  height: 20px;
  width: 5px;
  margin-right: 10px;
}

.contents {
  width: 100%;
  height: calc(100vh - 170px);
  overflow: auto;
  // margin-left: 30px;
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.leftitem {
  display: flex;
  flex-direction: column;
}

.topshow {
  font-size: 18px;
  margin-left: 20px;
  margin-top: 120px;
}

.rightcontent {
  box-sizing: border-box;
  padding: 25px;
  font-size: 15px;
  color: #6E6E6E;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}

.colcls {
  margin-top: 15px;
}

.showmessage {
  margin-top: 15px;
  display: flex;
}

.rightitem {
  width: 90%;
  height: auto;
  margin: 20px;
  margin-top: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}

.bg-purple {
  background: #d3dce6;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #eb9316;
}
.container {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  overflow: auto;
  background-color: #F6F3F7;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #212121;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
}
.main {
  padding: 20px 60px;
}

/deep/ .el-tabs__header {
  background-color: #fff;
  padding-left: 25px;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__item {
  font-size: 16px;
}
</style>
